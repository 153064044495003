import React from "react";

const Quotemark = ({ fill, ...props }) => (
  <svg
    width="271"
    height="199"
    viewBox="0 0 271 199"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M167.686 199L151.792 178.304C185.7 148.056 202.654 118.604 202.654 89.948C202.654 81.4573 199.74 73.4973 193.912 66.068C188.614 58.6387 185.965 49.6173 185.965 39.004C185.965 28.3907 190.733 19.3693 200.27 11.94C209.806 3.98 220.403 0 232.059 0C243.715 0 252.986 4.24533 259.874 12.736C267.291 20.696 271 32.9013 271 49.352C271 76.416 260.934 103.48 240.801 130.544C221.197 157.077 196.826 179.896 167.686 199ZM15.8944 199L0 178.304C33.9081 148.056 50.8622 118.604 50.8622 89.948C50.8622 81.4573 47.9482 73.4973 42.1202 66.068C36.8221 58.6387 34.173 49.6173 34.173 39.004C34.173 28.3907 38.9413 19.3693 48.478 11.94C58.0147 3.98 68.611 0 80.2669 0C91.9228 0 101.195 4.24533 108.082 12.736C115.5 20.696 119.208 32.9013 119.208 49.352C119.208 76.416 109.142 103.48 89.0088 130.544C69.4057 157.077 45.0342 179.896 15.8944 199Z"
      fill={fill}
    />
  </svg>
);

export default Quotemark;
